import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Smarthome/Alexa_Skill/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/SystemPerm';
import PrimaryBox from 'components/Web_User_Interface/1440p_Series/Smarthome/Alexa_Skill/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Smarthome Menu // INSTAR Alexa Skill",
  "path": "/Web_User_Interface/1440p_Series/Smarthome/Alexa_Skill/",
  "dateChanged": "2021-12-12",
  "author": "Mike Polinowski",
  "excerpt": "We provide both an INSTAR Cloud Skill and an INSTAR Camera Skill for you in the Alexa Skill Store. For INSTAR Cloud Skill you only need an INSTAR Cloud account and an Alexa device with a display. Add the skill on your Alexa device and use it directly.",
  "image": "./WebUI_1440p_SearchThumb_Smarthome_Alexa.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_Smarthome_Alexa.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Smarthome Menu // INSTAR Alexa Skill' dateChanged='2021-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='We provide both an INSTAR Cloud Skill and an INSTAR Camera Skill for you in the Alexa Skill Store. For INSTAR Cloud Skill you only need an INSTAR Cloud account and an Alexa device with a display. Add the skill on your Alexa device and use it directly.' image='/images/Search/WebUI_1440p_SearchThumb_Smarthome_Alexa.png' twitter='/images/Search/WebUI_1440p_SearchThumb_Smarthome_Alexa.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/Smarthome/Alexa_Skill/' locationFR='/fr/Web_User_Interface/1440p_Series/Smarthome/Alexa_Skill/' crumbLabel="Alexa" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "smarthome-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#smarthome-menu",
        "aria-label": "smarthome menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Smarthome Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8457543692eb0701982ad050d419e4bf/3c492/1440p_Settings_Smarthome_Alexa.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.04347826086956%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACs0lEQVQ4y6WSzWsUZxzH968QSg7Jvszu7OwGrNm3yczO+75ldzM7M9uYmdUgSvGt2hyq1JRCpYL4FqGXimCC0p5UqOBBUPGgF6EHT734x3xkJjFmhR7Ew4fn+T7MfPg+v5lUuVxGkiSkUol8LsN8Vce9sI1zfgv73D3s8zs4u2vrx3uMN/5mXu4w880BKtUqlUqFmZkZ0uk0qUQmSRSLRfKCgKxahKcuJUSnN5ic+SXZr578eY84N80eC99WUBa1hMpCnYMHF0iVSqVPwkIBVWlyeDwiXPH3iPNKMErWmMAd4AcRjjOgqZpoTQtDb9GoKzvCWJYI83lURWM1jIgmE6JoQhgT52jCZHKEMAzxfZ+V4AQ928U2TQzdRlMtajX5c2GBpqoxHo8ZjwOCIEj2nufhuUO8kUt/eZneYMD66hGOrXh0OzqGbiUta7XFnRnub6hrWiIajXx8P8D3PILDE8brtwhP/sq1pSGXnB6/nfmO9VNthgM9ubauWdRj4f4ZFgoF5IaCNxoShcv0lzoM+z263R6dQcBg4BIttbl88SL3717l7DGTlqVh6gaOadJoyNMNRbFAvSZj2y1ct0W7pWMZOh3HxO06fH98je3tP3n79jX/PfuHm+eOYhsqbcvAsQwa9cb0DOOGjYaCbbbpdTs4lkm/2+f2lZ94sHWHp89f8e7fN7x785L3L17w9PIGXkdPPoxl7F55f8NYWK3UUOQmymIT2zA4e2KNu5u/c+vmDR4/eczD7T+4v3mdR39tsXnhB9b6KrKsoCyqLByqTjcURZFMJkM6nUn+eiGXpZjPIeYFcrlckgUhR76QRyyKCPF5Nj31TiqWxHwUxodzc3N7zM7GzO7yeZ6dejYm9VG0X/g1pMREVPzfhl9KSiqXKZXnkUplilIJQRD2iOeWzWa/iA+gqwphyvZLUwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8457543692eb0701982ad050d419e4bf/e4706/1440p_Settings_Smarthome_Alexa.avif 230w", "/en/static/8457543692eb0701982ad050d419e4bf/d1af7/1440p_Settings_Smarthome_Alexa.avif 460w", "/en/static/8457543692eb0701982ad050d419e4bf/7f308/1440p_Settings_Smarthome_Alexa.avif 920w", "/en/static/8457543692eb0701982ad050d419e4bf/a2baf/1440p_Settings_Smarthome_Alexa.avif 1300w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8457543692eb0701982ad050d419e4bf/a0b58/1440p_Settings_Smarthome_Alexa.webp 230w", "/en/static/8457543692eb0701982ad050d419e4bf/bc10c/1440p_Settings_Smarthome_Alexa.webp 460w", "/en/static/8457543692eb0701982ad050d419e4bf/966d8/1440p_Settings_Smarthome_Alexa.webp 920w", "/en/static/8457543692eb0701982ad050d419e4bf/063bf/1440p_Settings_Smarthome_Alexa.webp 1300w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8457543692eb0701982ad050d419e4bf/81c8e/1440p_Settings_Smarthome_Alexa.png 230w", "/en/static/8457543692eb0701982ad050d419e4bf/08a84/1440p_Settings_Smarthome_Alexa.png 460w", "/en/static/8457543692eb0701982ad050d419e4bf/c0255/1440p_Settings_Smarthome_Alexa.png 920w", "/en/static/8457543692eb0701982ad050d419e4bf/3c492/1440p_Settings_Smarthome_Alexa.png 1300w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8457543692eb0701982ad050d419e4bf/c0255/1440p_Settings_Smarthome_Alexa.png",
              "alt": "Web User Interface - 1440p Series - Smarthome Alexa Skill",
              "title": "Web User Interface - 1440p Series - Smarthome Alexa Skill",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      